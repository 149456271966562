<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.order_payment") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button @click="reset()">{{ $t("message.reset") }}</el-button>
              <el-button @click="resetForm()">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <br />
    <div style="padding-left: 20px">
      <el-row>
        <el-col :span="10">
          <form
            class="mt-2 width-100 d-flex flex-wrap"
            v-on:submit.prevent="findOrder"
          >
            <el-input
              :placeholder="$t('message.enter_id')"
              v-model="id"
              class="input-with-select"
            >
              <!-- <el-select v-model="id" slot="prepend" placeholder="Selectasasassa">

                                <el-option v-for="item in paymentList" :key="item.id" :label="item.id" :value="item.id"></el-option>

                            </el-select> -->
              <el-button
                @click="findOrder()"
                slot="append"
                icon="el-icon-search"
              ></el-button>
            </el-input>
          </form>
        </el-col>
      </el-row>
    </div>
    <div v-if="order_show" class="grid-content">
      <div class="add_patient" v-loading="loading">
        <el-row>
          <el-tabs v-model="activeName" type="border-card">
            <el-tab-pane :label="$t('message.order_payment')" name="first">
              <table class="table table-striped table-bordered">
                <tbody>
                  <tr>
                    <td>{{ $t("message.order") }}:</td>
                    <td># {{ order_payments.id }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("message.total_amount") }}:</td>
                    <td>{{ order_payments.total_price | formatMoney }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t("message.paid") }}:</td>
                    <td>
                      {{
                        (payments.paid_price ? payments.paid_price : 0)
                          | formatMoney
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </el-tab-pane>
            <el-tab-pane :label="$t('message.order_service')" name="second">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ $t("message.name_service") }}</th>
                    <th>{{ $t("message.price2") }}</th>
                    <th>{{ $t("message.discount") }}</th>
                    <th>{{ $t("message.code") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="service in orderServices" :key="service.id">
                    <td>{{ service.id }}</td>
                    <td>{{ service.name }}</td>
                    <td>{{ service.total_price | formatMoney }}</td>
                    <td>{{ service.discount_price }}</td>
                    <td>{{ service.code }}</td>
                  </tr>
                </tbody>
              </table>
            </el-tab-pane>
          </el-tabs>

          <div class="mb-0 mt-4">
            <el-row
              class="button_l"
              v-if="
                order_payments.total_price !=
                (payments.paid_price ? payments.paid_price : 0)
              "
            >
              <el-col :span="24">
                <el-button
                  :loading="statusUpdateDebt"
                  :type="
                    order_payments.payment_status == 'debt' ? 'primary' : 'info'
                  "
                  round
                  @click="statusUpdate('debt')"
                  >{{ $t("message.lend") }}
                </el-button>
                <el-button
                  :loading="statusUpdateFreeCharge"
                  :type="
                    order_payments.payment_status == 'free_charge'
                      ? 'primary'
                      : 'info'
                  "
                  round
                  @click="statusUpdate('free_charge')"
                  >{{ $t("message.without_amount") }}
                </el-button>
                <el-button
                  v-if="
                    order_payments.payment_status == 'free_charge' ||
                    order_payments.payment_status == 'debt'
                  "
                  type="danger"
                  :loading="statusUpdateCancel"
                  round
                  @click="statusUpdate('cancel')"
                  >{{ $t("message.cancel") }}</el-button
                >
              </el-col>
            </el-row>
          </div>
        </el-row>

        <div
          class="grid-content formaner mt-3"
          style="padding: 10px 0px !important"
        >
          <i class="el-icon-s-grid mb-3"></i>
          {{ $t("message.add_transaction") }}
          <el-form
            ref="formTransaction"
            :model="form"
            :rules="rules"
            size="mini"
          >
            <el-row :gutter="24">
              <el-col :span="4">
                <el-form-item
                  :label="columns.payment_type_id.title"
                  prop="payment_type_id"
                >
                  <el-select
                    v-model="form.payment_type_id"
                    filterable
                    :placeholder="columns.payment_type_id.title"
                  >
                    <el-option
                      v-for="item in paymentTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>

              <!-- <el-col :span="4">
                            <el-form-item :label="columns.currency_id.title" prop="currency_id">
                                <el-select v-model="form.currency_id" filterable prop="currency_id" :placeholder="columns.currency_id.title">
                                    <el-option
                                    v-for="item in currency"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col> -->

              <el-col :span="5">
                <el-form-item :label="columns.price.title" prop="price">
                  <el-input
                    v-model="form.price"
                    :placeholder="columns.price.title"
                    @keyup.enter.native="submitTransaction"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item :label="columns.date.title" prop="date">
                  <el-date-picker
                    type="date"
                    v-model="form.date"
                    :placeholder="columns.date.title"
                    :format="'dd.MM.yyyy'"
                    :value-format="'dd.MM.yyyy'"
                  ></el-date-picker>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-form-item :label="columns.comment.title" prop="comment">
                  <el-input
                    v-model="form.comment"
                    :placeholder="columns.comment.title"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="5">
                <el-button
                  :loading="loadingButton"
                  :disabled="loadingButton ? true : false"
                  @click="submitTransaction()"
                  type="success"
                  >{{ $t("message.add_transaction") }}</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>

        <i class="el-icon-s-grid"></i> {{ $t("message.history_transaction") }}
        <!-- <el-row class="button_r" >
                    <el-button type="success" round @click="drawerCreate = true">Добавить </el-button>
                </el-row> -->
        <el-table :data="transactions" style="width: 100%" max-height="300">
          <el-table-column
            prop="payment_type.name"
            :label="$t('message.payment_type')"
          >
          </el-table-column>
          <el-table-column prop="price" :label="$t('message.price2')">
          </el-table-column>
          <el-table-column prop="currency.name" :label="$t('message.currency')">
          </el-table-column>
          <el-table-column prop="rate" :label="$t('message.rate')">
          </el-table-column>
          <el-table-column :label="$t('message.date_0')">
            <template slot-scope="scope">
              <span>{{ scope.row.date | dateFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="comment" :label="$t('message.comment')">
          </el-table-column>
          <el-table-column :label="$t('message.user')">
            <template slot-scope="scope">
              <span
                >{{ scope.row.user ? scope.row.user.name : " " }}
                {{ scope.row.user ? scope.row.user.surname : "" }}</span
              >
            </template>
          </el-table-column>
          <el-table-column prop="created_at" :label="$t('message.date')">
            <template slot-scope="scope">
              <span>{{ scope.row.created_at }}</span>
            </template>
          </el-table-column>

          <el-table-column width="180">
            <template slot-scope="scope">
              <div class="d-flex style_mioni_btu">
                <el-button type="text" @click="openDialog(scope.row)">{{
                  $t("message.back")
                }}</el-button>
                <el-dialog
                  :title="$t('message.back')"
                  :visible.sync="dialogVisible"
                  width="30%"
                  :append-to-body="true"
                >
                  <el-select
                    v-model="refund_type_id"
                    filterable
                    :placeholder="columns.payment_type_id.title"
                  >
                    <el-option
                      v-for="item in paymentTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                  <el-button
                    v-if="refundScope !== null"
                    type="success"
                    style="margin-left: 80px; padding: 12px !important"
                    @click.native.prevent="
                      updateRefund(refundScope.id, true, refund_type_id)
                    "
                    :loading="loadingRefundButton"
                    :disabled="refundScope.refund ? true : false"
                    :plain="refundScope.refund ? false : true"
                  >
                    <i class="el-icon-refresh-right"></i>
                    {{ $t("message.back") }}
                  </el-button>
                </el-dialog>

                <el-button
                  style="margin: 0 auto; display: none"
                  class="mt-1"
                  type="danger"
                  :disabled="scope.row.refund ? true : false"
                  @click.native.prevent="
                    deleteRow(scope.row.id, scope.$index, transactions)
                  "
                  size="small"
                >
                  <i class="el-icon-delete"></i>
                  {{ $t("message.delete") }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

        <el-drawer
          :visible.sync="drawerCreate"
          size="60%"
          :drawer="drawerCreate"
          :append-to-body="true"
        >
          <div>
            <crm-create
              @c-close="closeDrawer"
              ref="create"
              :reloadModel="reopenUpdate"
            ></crm-create>
          </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import CrmCreate from "./crm-create";
export default {
  components: {
    CrmCreate,
  },
  props: {
    reloadModel: { type: Boolean, default: true },
  },
  data() {
    return {
      activeName: "first",
      loadingButton: false,
      statusUpdateDebt: false,
      dialogVisible: false,
      statusUpdateFreeCharge: false,
      statusUpdateCancel: false,
      loading: false,
      id: "",
      order_show: false,
      drawerCreate: false,
      reopenUpdate: false,
      order_payments: [],
      loadingRefundButton: false,
      orderServices: [],
      refund_type_id: null,
      payments: [],
      transactions: [],
      refundScope: null,
      form: {},
    };
  },
  computed: {
    ...mapGetters({
      currency: "currencies/list",
      paymentTypes: "paymentTypes/list",
      columns: "transaction/columns",
      paymentList: "payment/list",
      model: "payment/order_payments",
      rules: "transaction/rules",
    }),
  },
  watch: {
    model: {
      handler: function () {
        this.order_payments = JSON.parse(JSON.stringify(this.model));
        this.payments =
          typeof this.model.payments[0] !== "undefined"
            ? JSON.parse(JSON.stringify(this.model.payments[0]))
            : [];
        this.orderServices =
          typeof this.model.order_services !== "undefined"
            ? JSON.parse(JSON.stringify(this.model.order_services))
            : [];
        this.transactions =
          typeof this.model.payments[0] !== "undefined"
            ? JSON.parse(JSON.stringify(this.model.payments[0].transactions))
            : [];
      },
      deep: true,
    },
  },
  created() {
    this.currenciesList();
    this.paymentTypesList();
    this.form.date = new Date();
    // this.order_payments = JSON.parse( JSON.stringify( this.model ));
    // this.payments = typeof this.model.payments[0] !== 'undefined' ? JSON.parse( JSON.stringify( this.model.payments[0] )) : [];
    // this.transactions = typeof this.model.payments[0] !== 'undefined' ? JSON.parse( JSON.stringify( this.model.payments[0].transactions )) : [];
  },
  async mounted() {},
  methods: {
    ...mapActions({
      delTransaction: "transaction/remove",
      showModel: "payment/relationShow",
      updateStatus: "payment/updateStatus",
      currenciesList: "currencies/index",
      paymentTypesList: "paymentTypes/index",
      save: "payment/paymentStore",
      updatetTransactionRefund: "transaction/refund",
    }),
    openDialog(scope) {
      this.refund_type_id = null;
      this.refundScope = scope;
      this.dialogVisible = true;
    },
    findOrder() {
      this.loading = true;
      const query = { id: this.id, relation: true };
      this.showModel(query)
        .then((res) => {
          this.order_show = true;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.order_show = false;
          this.$notify({
            title: "Ошибка ",
            type: "error",
            offset: 130,
            dangerouslyUseHTMLString: true,
            message: "Заказ не найден",
          });
        });
    },
    fetchData() {
      const query = { id: this.order_payments.id, relation: true };
      this.showModel(query)
        .then((res) => {
          this.drawerUpdate = true;
        })
        .catch((err) => {});
    },
    loadModel() {
      // this.order_payments = JSON.parse( JSON.stringify( this.model ));
      // this.payments = typeof this.model.payments[0] !== 'undefined' ? JSON.parse( JSON.stringify( this.model.payments[0] )) : [];
      // this.transactions = typeof this.model.payments[0] !== 'undefined' ? JSON.parse( JSON.stringify( this.model.payments[0].transactions )) : [];
    },
    submitTransaction() {
      this.$refs["formTransaction"].validate((valid) => {
        if (valid) {
          this.saveAction(true);
        }
      });
    },
    saveAction(close) {
      let price =
        typeof this.order_payments.payments[0] != "undefined"
          ? this.order_payments.payments[0].paid_price
          : 0;
      let val = this.order_payments.total_price - price;
      if (val == NaN && val < 0) {
        this.$notify({
          title: "Ошибка ",
          type: "error",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: "Намного больше оплачено" + val,
        });
        return;
      }
      if (val < this.form.price) {
        let dd = this.form.price - val;
        this.$notify({
          title: "Ошибка ",
          type: "error",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: "более выплаченная сумма  " + dd,
        });
        return;
      }
      if (this.form.price <= 0) {
        this.$notify({
          title: "Внимание ",
          type: "warning",
          offset: 130,
          dangerouslyUseHTMLString: true,
          message: "Укажите сумму транзакции",
        });
        return;
      }

      this.form.paymentable_id = this.order_payments.id;
      this.form.paymentable_type = "App\\Order";
      this.form.total_price = this.order_payments.total_price;
      this.form.debit = 1;
      this.form.rate = 1;

      this.loadingButton = true;
      this.save(this.form)
        .then((res) => {
          this.$parent.$parent.listChanged();
          this.loadingButton = false;
          this.$alert(res);
          this.fetchData();
          this.form = {};
          this.form.date = new Date();
          if (close) {
            this.$refs["formTransaction"].resetFields();
          } else {
          }
        })
        .catch((err) => {
          this.loadingButton = false;
          this.$alert(err);
        });
    },
    statusUpdate(status) {
      if (status == "free_charge") this.statusUpdateFreeCharge = true;
      if (status == "debt") this.statusUpdateDebt = true;
      if (status == "cancel") this.statusUpdateCancel = true;

      let paid_price = this.payments.paid_price ? this.payments.paid_price : 0;
      let payments =
        paid_price == this.order_payments.total_price ? "paid" : "unpaid";

      const query = {
        id: this.order_payments.id,
        status: status,
        payments: payments,
        relation: true,
      };

      this.updateStatus(query)
        .then((res) => {
          this.$parent.$parent.listChanged();
          this.statusUpdateFreeCharge = false;
          this.statusUpdateDebt = false;
          this.statusUpdateCancel = false;
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          this.statusUpdateFreeCharge = false;
          this.statusUpdateDebt = false;
          this.statusUpdateCancel = false;
          this.$alert(res);
        });
    },
    updateRefund(id, refund, refund_type_id) {
      let data = { id: id, refund: refund, refund_type_id: refund_type_id };
      this.loadingRefundButton = true;
      this.updatetTransactionRefund(data)
        .then((res) => {
          this.$parent.$parent.listChanged();
          this.loadingRefundButton = false;
          this.$alert(res);
          this.fetchData();
          this.dialogVisible = false;
        })
        .catch((err) => {
          this.loadingRefundButton = false;
          this.$alert(err);
        });
    },
    deleteRow(id, index, transactions) {
      let data = { transaction_id: id };
      this.delTransaction(data)
        .then((res) => {
          this.$parent.$parent.listChanged();
          this.$alert(res);
          this.fetchData();
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
    reset() {
      this.id = "";
      this.order_show = false;
    },
    resetForm() {
      this.order_show = false;
      this.id = "";
      this.$emit("c-close", { drawer: "tabAddPayment", reload: true });
    },
    closeDrawer(obj) {
      if (obj.reload) {
        this.fetchData();
      }
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
  },
};
</script>
<style>
.order_payment_table {
  align-content: center;
  border: 1px solid #ccc;
}
.order_payment_table tr {
  border: 1px solid #ccc;
}
</style>